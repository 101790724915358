<template>
  <v-select
    v-bind="propsFormFields"
    :disabled="disabled"
    :loading="loading"
    v-model="editPickerValue"
    :items="organisationLevels"
    item-text="name"
    item-value="value"
    :label="$t('organisations.levels.formSelect')"
    :no-data-text="$t('common.selectNoDataText')"
    :error-messages="errorMessages"
    @change="pickerChanged"
    @blur="$emit('blur')"
    :clearable="clearable"
  ></v-select>
</template>

<script>
import APP_CONSTANTS from "@/appConstants";

export default {
  name: "OrganisationLevelPickerSelect",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    pickerValue: {
      type: Number,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    changed: false,
    editPickerValue: null,
  }),
  computed: {
    loggedInOrganisationLevel() {
      return this.$store.getters["auth/getOrganisationLevel"];
    },
    organisationLevels() {
      let organisationLevels = APP_CONSTANTS.ORGANISATION_LEVELS.map((item) => {
        return {
          name: this.$t(`organisations.levels.${item.name}`),
          value: item.value,
          disabled:
            item.value === APP_CONSTANTS.ORGANISATION_LEVELS_SYSTEM
              ? true
              : false,
        };
      });
      organisationLevels = organisationLevels.filter((item) => {
        return item.value > this.loggedInOrganisationLevel;
      });
      return organisationLevels;
    },
  },
  watch: {
    pickerValue: {
      immediate: true,
      handler: function (value) {
        // if (!this.changed) {
        this.editPickerValue = value;
        // }
      },
    },
  },
  created() {},
  methods: {
    pickerChanged(value) {
      this.changed = true;
      this.$emit("pickerChanged", value);
    },
  },
};
</script>
