<template>
  <v-select
    v-bind="propsFormFields"
    :disabled="disabled"
    :loading="loading"
    v-model="editPickerValue"
    :items="organisationsSorted"
    item-text="name"
    item-value="id"
    :label="$t('organisations.parentOrganisationId')"
    :no-data-text="$t('common.selectNoDataText')"
    :error-messages="errorMessages"
    @change="pickerChanged"
    @blur="$emit('blur')"
    :clearable="clearable"
  ></v-select>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import { mapGetters } from "vuex";

export default {
  name: "OrganisationForReparentingPickerSelect",
  mixins: [ComponentStatus],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    pickerValue: {
      type: Number,
      default: null,
    },
    ownOrganisationId: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    changed: false,
    editPickerValue: null,
  }),
  computed: {
    organisationsSorted() {
      let organisationsSorted = this.organisations.slice(0).sort((a, b) => {
        return a.hierarchy - b.hierarchy;
      });

      organisationsSorted.forEach((item) => {
        if (item.id == this.ownOrganisationId) {
          item.disabled = true;
        }
      });

      return organisationsSorted;
    },
    ...mapGetters({
      organisations: "organisationsFromParent/readAll",
    }),
  },
  watch: {
    pickerValue: {
      immediate: true,
      handler: function (value) {
        // if (!this.changed) {
        this.editPickerValue = value;
        // }
      },
    },
  },
  created() {
    this.setStatusReading();
    this.$store
      .dispatch("organisationsFromParent/readAllForReparenting")
      .then((/* result */) => {
        this.setStatusReadSuccess();
      })
      .catch((/* error */) => {
        this.setStatusReadError();
        this.$store.commit("status/showError");
      });
  },
  methods: {
    pickerChanged(value) {
      this.changed = true;
      this.$emit("pickerChanged", value);
    },
  },
};
</script>
