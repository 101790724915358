<template>
  <v-container
    v-bind="propsPageMainContainer"
    data-cy="organisations-edit-from-parent-page"
  >
    <v-row v-bind="propsPageMainRow">
      <v-col v-bind="propsPageMainCol">
        <v-card v-bind="propsPageMainCard">
          <div v-bind="propsPageTitleBar">
            <div v-bind="propsPageTitle">
              {{ $t("organisations.editTitle") }}
            </div>
            <div class="flex-grow-1"></div>
            <v-btn
              class="mr-2"
              v-bind="propsButtonIconAction"
              small
              :to="{ name: 'organisations-index' }"
              data-cy="button-index"
            >
              <v-icon>$pageIndex</v-icon>
            </v-btn>
            <v-btn
              v-bind="propsButtonIconAction"
              small
              :to="{ name: 'organisations-show', params: { id: objectId } }"
              data-cy="button-show"
            >
              <v-icon>$pageShow</v-icon>
            </v-btn>
          </div>
          <OrganisationsEditFromParent
            :objectId="objectId"
          ></OrganisationsEditFromParent>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import OrganisationsEditFromParent from "../components/OrganisationsEditFromParent";

export default {
  name: "OrganisationsEditFromParentPage",
  mixins: [Permissions],
  components: {
    OrganisationsEditFromParent,
  },
  computed: {
    objectId() {
      let objectId = "";
      if (this.$route.params.id) {
        objectId = this.$route.params.id.toString();
      }
      return objectId;
    },
  },
};
</script>
