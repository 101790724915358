export default {
  computed: {
    editNameErrors() {
      const errors = [];
      // Checking if the validator has been defined.
      if ("editName" in this.$v) {
        if (!this.$v.editName.$dirty) return errors;
        // Checking for specific validators.
        if ("required" in this.$v.editName && !this.$v.editName.required) {
          errors.push(this.$t("common.errors.required"));
        }
        if ("maxLength" in this.$v.editName && !this.$v.editName.maxLength) {
          errors.push(this.$t("common.errors.maxLength"));
        }
      }
      return errors;
    },
    editAddressErrors() {
      const errors = [];
      // Checking if the validator has been defined.
      if ("editAddress" in this.$v) {
        if (!this.$v.editAddress.$dirty) return errors;
        // Checking for specific validators.
        if (
          "required" in this.$v.editAddress &&
          !this.$v.editAddress.required
        ) {
          errors.push(this.$t("common.errors.required"));
        }
        if (
          "maxLength" in this.$v.editAddress &&
          !this.$v.editAddress.maxLength
        ) {
          errors.push(this.$t("common.errors.maxLength"));
        }
      }
      return errors;
    },
    editCityErrors() {
      const errors = [];
      // Checking if the validator has been defined.
      if ("editCity" in this.$v) {
        if (!this.$v.editCity.$dirty) return errors;
        // Checking for specific validators.
        if ("required" in this.$v.editCity && !this.$v.editCity.required) {
          errors.push(this.$t("common.errors.required"));
        }
        if ("maxLength" in this.$v.editCity && !this.$v.editCity.maxLength) {
          errors.push(this.$t("common.errors.maxLength"));
        }
      }
      return errors;
    },
    editPostalCodeErrors() {
      const errors = [];
      // Checking if the validator has been defined.
      if ("editPostalCode" in this.$v) {
        if (!this.$v.editPostalCode.$dirty) return errors;
        // Checking for specific validators.
        if (
          "required" in this.$v.editPostalCode &&
          !this.$v.editPostalCode.required
        ) {
          errors.push(this.$t("common.errors.required"));
        }
        if (
          "maxLength" in this.$v.editPostalCode &&
          !this.$v.editPostalCode.maxLength
        ) {
          errors.push(this.$t("common.errors.maxLength"));
        }
      }
      return errors;
    },
    editCountryIso3166_a2Errors() {
      const errors = [];
      // Checking if the validator has been defined.
      if ("editCountryIso3166_a2" in this.$v) {
        if (!this.$v.editCountryIso3166_a2.$dirty) return errors;
        // Checking for specific validators.
        if (
          "required" in this.$v.editCountryIso3166_a2 &&
          !this.$v.editCountryIso3166_a2.required
        ) {
          errors.push(this.$t("common.errors.required"));
        }
        if (
          "maxLength" in this.$v.editCountryIso3166_a2 &&
          !this.$v.editCountryIso3166_a2.maxLength
        ) {
          errors.push(this.$t("common.errors.maxLength"));
        }
      }
      return errors;
    },
    editLanguageIso639_1Errors() {
      const errors = [];
      // Checking if the validator has been defined.
      if ("editLanguageIso639_1" in this.$v) {
        if (!this.$v.editLanguageIso639_1.$dirty) return errors;
        // Checking for specific validators.
        if (
          "required" in this.$v.editLanguageIso639_1 &&
          !this.$v.editLanguageIso639_1.required
        ) {
          errors.push(this.$t("common.errors.required"));
        }
        if (
          "maxLength" in this.$v.editLanguageIso639_1 &&
          !this.$v.editLanguageIso639_1.maxLength
        ) {
          errors.push(this.$t("common.errors.maxLength"));
        }
      }
      return errors;
    },
    editDescriptionErrors() {
      const errors = [];
      // Checking if the validator has been defined.
      if ("editDescription" in this.$v) {
        if (!this.$v.editDescription.$dirty) return errors;
        // Checking for specific validators.
        if (
          "required" in this.$v.editDescription &&
          !this.$v.editDescription.required
        ) {
          errors.push(this.$t("common.errors.required"));
        }
        if (
          "maxLength" in this.$v.editDescription &&
          !this.$v.editDescription.maxLength
        ) {
          errors.push(this.$t("common.errors.maxLength"));
        }
      }
      return errors;
    },
  },
};
